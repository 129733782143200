import { app_env, absa_env } from "packages/shared-lib/src/environments/environment";
const domain = window.location.href;
let currentEnv = app_env;
let absaDomain = false;
if(domain.includes('absa')) {
    window.location.href = domain.replace('absa', 'app');
}
window.localStorage.setItem('absaDomain', 'false');
const domainUrl = window.localStorage.getItem('absaDomain') === 'true' ? 'absa/' : 'enZA/';
const supportVideoUrl = currentEnv.supportVideoBaseUrl;

export const webConstants = {

  //header support
  headerHelpvideUrl: supportVideoUrl + domainUrl,

  // Prefixandstartingnumber settings page
  prefixSettingsVideoURL: supportVideoUrl + domainUrl + 'videos/addcompanyprofile',

  //import bank accounts
  importBankAccountsVideoURL: supportVideoUrl + domainUrl + 'videos/importbankstatement',


  // allocate page
  alloacteVideoURL: supportVideoUrl + domainUrl + 'videos/allocate',

  // pricelist page
  pricelistVideoURL: supportVideoUrl + domainUrl + 'videos/pricelist',

   /**
   * settings/bankingdetails page
   */
  exportDataVideoURL: supportVideoUrl + domainUrl + 'videos/exportdata',

    /**
   * settings/bankingdetails page
   */
  bankingDetailsVideoURL: supportVideoUrl + domainUrl + 'videos/addcompanyprofile',
  deleteConfirmationMessage: 'Are you sure you want to delete',
  deletedSuccessMessage: 'Deleted successfully',
  /**
   * Contacts page
   */
  organisationDetailsHelpURL: supportVideoUrl + domainUrl + 'videos/contacts',
  projectHelpURL: supportVideoUrl + domainUrl + 'videos/addproject',

  /**
   * finance/Business Cash page
   */
  businessCashHelpVideoURL: supportVideoUrl + domainUrl + 'videos/businesscash',

  /**
 * Sales Leads page
 */
  supportVideoUrl: supportVideoUrl + domainUrl + 'videos/salesleads',

  /**
 * Staff Details page
 */
  staffDetailsHelpURL: supportVideoUrl + domainUrl + 'videos/addstaff',
  /**
   * * Payslips page
 */
  searchPayslipURL: supportVideoUrl + domainUrl + 'videos/searchpayslip',
  /**
   *  * Payslips + page
 */
  addPayslipVideoUrl: supportVideoUrl + domainUrl + 'videos/addpayslip',
  /**
   * Supplier invoice - finance page
   */
  supplierInvoiceHelpURL: supportVideoUrl + domainUrl + 'videos/invoicein',
  /**
 * Company Profile -Settings page
 */
   companyProfileHelpUrl: supportVideoUrl + domainUrl + 'videos/addcompanyprofile',

  /**
   * Cash flow page - reports page 
   */ 
  cashFlowHelpUrl : supportVideoUrl + domainUrl + 'videos/cashflowreport',
   /**
    * Staff Details page
    */
  ownersMoneyURL: supportVideoUrl + domainUrl + 'videos/ownersmoney',

  /**
 * Package Details - Staff
 */
  packageDetailsURL: supportVideoUrl + domainUrl + 'videos/staffpackagedetails',

  /**
   * Settings page
   */
  /**
  * System User acces page - User Details 
   */
   userDetailHelpUrl:supportVideoUrl + domainUrl + 'videos/systemuserdetails',
   userPermissionHelpUrl : supportVideoUrl + domainUrl + 'videos/systemuseraccess',
   /**
    * Quotes page - finance
    */
   quotesHelpURL: supportVideoUrl + domainUrl + 'videos/addquote',
   /**
    * Business Loans page - finance
    */
   businessLoansURL: supportVideoUrl + domainUrl + 'videos/businessloans',

    /**
    * Supplier Invoice 
    */
  supplierInvoiceOwedHelpURL: supportVideoUrl + domainUrl + 'videos/whoweowereport',
  supplierAgeAnalysisHelpURL: supportVideoUrl + domainUrl + 'videos/supplierageanalysisreport',

   /**
  * Salary schedule - 
   */
    companySalaryScheduleHelpUrl:supportVideoUrl + domainUrl + 'videos/companysalaryschedule',
  individualSalaryScheduleHelpUrl: supportVideoUrl + domainUrl + 'videos/individualsalaryschedule',

   /**
  * Accountant - general ledger
   */
   generalLedgerHelpURL: supportVideoUrl + domainUrl + 'videos/generalledger',

   /**
  * Accountant - Trial balance
   */
  trialBalanceHelpURL: supportVideoUrl + domainUrl + 'videos/trialbalance',

  /**
  * Accountant - custom ledger
   */
  customLedgerAccountHelpUrl: supportVideoUrl+ domainUrl + 'videos/customledgeraccounts',


     /**
  * customer age analysis - 
   */
    customerAgeAnalysisHelpUrl : supportVideoUrl + domainUrl + 'videos/customerageanalysisreport',
   /**
  * Accountant - query 
   */
  queryHelpUrl : supportVideoUrl + domainUrl + 'videos/askmyaccountant',
  /**
   * Vat - reports page
   */
  reportHelpUrl: supportVideoUrl + domainUrl + 'videos/vatreport',
    /**
  * bank-statements view by month - 
   */
    bankStatementsViewByMonthHelpUrl: supportVideoUrl + domainUrl + 'videos/savedbankstatements',

   /**
  * Reports - Customer invoices due by date
   */
    customerInvoiceHelpUrl :supportVideoUrl + domainUrl + 'videos/whoowesusreport',
  /**
  * Finance - Credit Note
   */
  creditNoteHelpUrl: supportVideoUrl + domainUrl + 'videos/creditnote',
    /**
  * Reports - Supplier Invoice Owed
   */
  supplierInvoiceOwedHelpUrl : supportVideoUrl + domainUrl + 'videos/whoweowereport',
  /**
 * Accountant- Adjustments
 */
  adjustmentHelpUrl: supportVideoUrl + domainUrl + 'videos/adjustments',
    /**
 * Accountant- Set Up
 */
  setUpHelpURL : supportVideoUrl + domainUrl + 'videos/setup',


   /**
  * Accountant - Balance Sheet
   */
   balanceSheetURL: supportVideoUrl + domainUrl + 'videos/balancesheet',

    /**
  * Accountant - Balance Sheet
   */
  incomeStatementURL: supportVideoUrl + domainUrl + 'videos/incomestatement',

    /**
  * Company profile wizard
   */
  companyprofilesetupURL: supportVideoUrl + domainUrl + 'videos/companyprofilesetup',

  dateFormats: [
    { dateFormat: 'dd/MM/yyyy' },
    { dateFormat: 'dd-MM-yyyy' },
    { dateFormat: 'd/M/yyyy' },
    { dateFormat: 'd-M-yyyy' },
    { dateFormat: 'd-MMM-yy' },
    { dateFormat: 'M/d/yyyy' },
    { dateFormat: 'M-d-yyyy' },
    { dateFormat: 'MM/dd/yyyy' },
    { dateFormat: 'MM-dd-yyyy' },
    { dateFormat: 'yyyy/MM/dd' },
    { dateFormat: 'yyyy-MM-dd' }
  ],
  
    /**
   * Generic Starting Details Modal
   */
  startingNumber: 'Starting Number',
  startingDetail: 'Starting Details',
  prefix: 'Prefix',
  phrase1: 'Before you proceed you need to first confirm the document prefix and the starting number you wish to use for your documents. You can either accept the defaults below or enter your own values.',
  phrase2: 'Please make sure your starting number is correct, you wont be able to change once you click Continue.',
  /**
  * Generic Email Modal
  */
  emailName: 'Email',
  sendName: 'Send',
  cancelName: 'Cancel',
  phrase: 'View the document below to ensure this is correct',
  /**
 * Generic Modal
 */
  closeData: 'Close Data',
  okClick: 'OK-Click',
  /**
   * common
   */
  exportText: 'Export',
  recordDeleteSuccessMessage: 'Record deleted successfully.',
  checkboxUpdateSuccessMessage: 'Record updated successfully.',
    /**
   * common
   */
  datePlaceHolderMessage: 'DD/MM/YYYY e.g. 25/01/2016',
  /* Supplier invoice - finance page
  */
  customerInvoiceHelpURL: supportVideoUrl + domainUrl + 'videos/invoiceout',

  /* Page Names
  */
  payslipPage: 'payslip',

  requestGL: 'Request GL',

  currentTheme: absaDomain ? 'absa' : 'app',

  /* Customer invoices - finance page
  */
  isPaidTitle: 'This tick box is automatically populated when you allocate payment to this invoice in the finance dashboard.',
  paidInfo: 'Invoice Paid Info',

  permissions: {
    None: 0,
    CanAccessSettingsCompanyProfile: 1,
    CanAccessSettingsSetup: 2,
    CanAccessSettingsSystemAccess: 3,
    CanAccessContactsCompany: 4,
    CanAccessContactsIndividual: 5,
    CanAccessStaffStaffDetails: 8,
    CanAccessStaffSalarySchedule: 9,
    CanAccessStaffPayslip: 10,
    CanAccessFinanceQuotes: 11,
    CanAccessFinanceInvoicing: 12,
    CanAccessFinanceBusinessCash: 13,
    CanAccessFinanceBankAccounts: 14,
    CanAccessFinanceOwnersMoney: 15,
    CanAccessFinanceBusinessLoans: 16,
    CanAccessReportsWhoOwesUs: 17,
    CanAccessReportsWhoWeOwe: 18,
    CanAccessReportsCashGauge: 19,
    CanAccessReportsWhoOwesUsCustomer: 20,
    CanAccessReportsBankStatements: 21,
    CanAccessReportsVat: 22,
    CanAccessAccountantGeneralLedger: 23,
    CanAccessAccountantIncomeStatement: 24,
    CanAccessAccountantTrailBalance: 25,
    CanAccessAccountantBalanceSheet: 26,
    CanAccessAccountantCustomLedgerAccounts: 27,
    CanAccessAccountantExportData: 28,
    CanAccessAccountantCashFlow: 29,
    CanAccessFinanceInvoiceOut: 30,
    CanAccessFinanceInvoiceIn: 31,
    CanAccessFinanceCreditNote: 32,
    CanAccessAccountantAdjustments: 33,
    CanAccessSettingsImportContactsCsv: 34,
    CanAccessAccountantAskMyAccountant: 35,
    CanAccessFinancePaymentPebble: 36,
    CanAccessSettingVatRegistration: 37,
    CanAccessSettingsImport: 38,
    CanAccessSettingsImportHistoricalBankStatement: 39,
    CanAccessSettingsImportHistoricalCustomerInvoice: 40,
    CanAccessDashboard: 41
  },

  /**
   * env varibles absa or app
   */
  currentEnv: currentEnv,

  /**
   * Absa Error message
   */
  absaErrorMessage:'Dear user,<br/>As you are aware Absa are no longer providing you with complimentary access to your Cashflow Manager system.<br/>You have been automatically migrated to the SMEasy and will need to pay to continue to use the service from 1 March 2024.<br/>All the information that you have previously captured on your Cashflow Manager system is still available to you – the only difference is that the screens will be blue not red.<br/><br/><b>To continue using the system, please click on the button below be redirected to the payment link. The cost is R240 per month or you can buy an annual licence for R2400 (giving you two free months)!</b><br/>Please contact our support team should you need any assistance on 031 880 4505 or <a href="mailto:support@smeasy.co.za">support@smeasy.co.za</a><br /><br /><center><a href="https://www.smeasy.co.za/enza/PaymentDetails/index/?token=#company#&signupType=renewal">PAY HERE</a></center>'
};

Object.freeze(webConstants);